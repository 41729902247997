import {
  useMoralisQuery,
  // useMoralisCloudFunction,
} from 'react-moralis'

import SubscriptionClass from '../classes/SubscriptionClass'

import { IoMdMoon } from 'react-icons/all'

export const Subscription = ({ productId }: { productId: string }) => {
  const { data, error, isLoading } = useMoralisQuery('Subscription', (query) =>
    query.equalTo('objectId', productId)
  )
  const {
    // fetch: invfetch,
    data: invdata,
    // error: inverror,
    // isLoading: invloading,
  } = useMoralisQuery('Invoices', (query) =>
    query.equalTo('subscriptionid', productId).equalTo('status', 'pending')
  )

  if (error) {
    return <span>🤯</span>
  }

  if (isLoading) {
    return <span>🙄</span>
  }

  let json = JSON.stringify(data, null, 2)
  const product: SubscriptionClass = JSON.parse(json)[0]
  let recurrence = ''
  let price = 0
  let name = ''
  let email = ''
  // let on_suc = 'subscription'
  if (product !== undefined) {
    recurrence = product.recurrence
    price = product.price
    name = product.name
    email = product.email_address
    // if (recurrence === 'One Time') {
    //   on_suc = 'product'
    // }
  }

  return (
    <div
      style={{
        backgroundImage: 'url(/images/moon_bg.png)',
      }}
      className='w-screen h-screen bg-moon bg-center bg-no-repeat bg-cover flex justify-center items-center'
    >
      <div className='w-5/12 h-3/4 rounded-2xl bg-background flex flex-col justify-between items-center shadow-default'>
        <div className='flex justify-center items-center w-full mt-3'>
          <div className='flex justify-center items-center gap-1'>
            <IoMdMoon className='text-2xl text-primary' />
            <p className='text-2xl text-primary font-medium'>lunar</p>
          </div>
        </div>
        <div className='flex flex-col justiy-center items-center gap-3 mb-5 w-full'>
          <h1 className='text-2xl w-60 flex items-center font-medium justify-center w-full'>
            {email}
          </h1>
          <h1 className='text-2xl w-60 flex items-center font-medium justify-center w-full'>
            {name}
          </h1>
          <h1 className='text-5xl text-primary w-60 flex items-center font-semibold justify-center mb-5 w-full'>
            {price} MATIC
          </h1>
          <h5 className='text-grey text-2xl font-display font-medium my-1'>
            Interval: {recurrence}
          </h5>
          <div
            onClick={async () => {
              if (data[0] !== undefined) {
                data[0].destroy().then(() => {
                  if (invdata[0] !== undefined) {
                    invdata[0].destroy()
                  }
                })
              }
            }}
            className='w-60 bg-primary h-11 flex justify-center items-center rounded-sm font-semibold mt-5 font-display text-xl shadow-button cursor-pointer'
          >
            Cancel
          </div>
          <p className='text-xs mt-5 font-extralight'>
            secured by <span className='font-bold'>lunar</span>
          </p>
        </div>
        <div></div>
      </div>
    </div>
  )
}
