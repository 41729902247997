// Modal
import { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { useWeb3Transfer, useMoralis } from 'react-moralis'
import Moralis from 'moralis'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Loader from 'react-loader-spinner'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#1E1E1F',
    border: 'none !important',
  },
}

const DonationModal = ({
  user,
  setIsOpen,
  modalIsOpen,
  setSuccessful,
}: {
  user: string
  modalIsOpen: boolean
  setIsOpen: (arg: boolean) => void
  setSuccessful: (arg: boolean) => void
}) => {
  const [email, setEmail] = useState('')
  const [price, setPrice] = useState(0)

  function closeModal() {
    setIsOpen(false)
  }
  function setWasSuccessful() {
    setSuccessful(true)
  }

  useEffect(() => {
    ReactModal.setAppElement('body')
    if (ReactModal.defaultStyles.overlay !== undefined)
      ReactModal.defaultStyles.overlay.backgroundColor = 'transparent'
  }, [])

  return (
    <ReactModal
      id='donate_modal'
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Done Modal'
    >
      <div className='w-full h-full flex flex-col justify-center items-center gap-7 py-9 px-4'>
        <div className='flex flex-col justify-center items-center gap-5'>
          <div className='flex justify-center items-center gap-2'>
            <p className='font-medium text-sm'>EMAIL</p>
            <input
              type='email'
              name='email'
              id='email'
              placeholder='Enter email..'
              className='rounded-sm outline-none pl-2 font-display h-7 flex justify-center items-center'
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <input
              type='number'
              name='price'
              id='price'
              placeholder='Enter donation amount'
              className='rounded-sm outline-none pl-2 font-display h-7 flex justify-center items-center'
              value={price}
              onChange={(event) => setPrice(parseFloat(event.target.value))}
            />
          </div>
        </div>
        <Transfer
          email={email}
          price={price}
          user={user}
          setWasSuccessful={setWasSuccessful}
          closeModal={closeModal}
        />
      </div>
    </ReactModal>
  )
}

export default DonationModal

const Transfer = ({
  price,
  email,
  user,
  setWasSuccessful,
  closeModal,
}: {
  price: number
  email: string
  user: string
  setWasSuccessful: () => void
  closeModal: () => void
}) => {
  const {
    authenticate,
    isAuthenticated,
    enableWeb3,
    setUserData,
    isWeb3Enabled,
  } = useMoralis()
  const [called, setcalled] = useState(false)
  const { fetch: moralisFetch } = useWeb3Transfer({
    amount: price > 0 ? Moralis.Units.ETH(price) : Moralis.Units.ETH(price),
    receiver: user,
    type: 'native',
  })

  return (
    <div>
      <button
        onClick={async () => {
          if (!isAuthenticated) {
            await authenticate()
          }
          if (!isWeb3Enabled) {
            enableWeb3()
          }
          if (isAuthenticated) {
            setUserData({ email_address: email })
            setcalled(true)
          }
          // moralisFetch().then(async (res) => {
          //   setWasSuccessful();
          // });
          moralisFetch({
            onSuccess: async () => {
              setWasSuccessful()
              closeModal()
            },

            onError: (error) => {
              // console.log('error', error)
            },
          }).then()
        }}
        className='px-14 py-1 bg-primary rounded-sm flex justify-center items-center font-semibold cursor-pointer'
      >
        {!called ? (
          <span>Transfer</span>
        ) : (
          <div className='flex justify-center items-center '>
            <span>Transferring </span>
            <Loader type='Puff' color='black' height={20} width={30} />
          </div>
        )}
      </button>
    </div>
  )
}
