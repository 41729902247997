import {
  useWeb3Transfer,
  useMoralis,
  useChain,
  // useMoralisCloudFunction,
} from 'react-moralis'
import Moralis from 'moralis'
import { useState } from 'react'

// Spinner
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Loader from 'react-loader-spinner'

// Classes
import { successsaver } from '../API/success'
import { demographicsadder, demographicsgetter } from '../API/demographics'

const TransferPayment = ({
  email,
  receiverAddress,
  product,
  price,
  currency,
  invdata,
  processor,
  closeModal,
  setWasSuccessful,
}: {
  email: string
  receiverAddress: string
  price: number
  currency: string
  invdata: any
  processor: string
  product: any
  closeModal: () => void
  setWasSuccessful: () => void
}) => {
  const { setUserData } = useMoralis()
  const { enableWeb3, isWeb3Enabled, authenticate, isAuthenticated } =
    useMoralis()
  const [requestCount, setRequestCount] = useState(0)
  const { switchNetwork, chainId } = useChain()
  const [fetched, setFetched] = useState(false)
  const [called, setcalled] = useState(false)
  const { fetch: moralisFetch } = useWeb3Transfer({
    amount:
      product !== undefined ? Moralis.Units.ETH(price) : Moralis.Units.ETH(0),
    receiver: receiverAddress,
    type: 'native',
  })

  const [data, setAccounts] = useState([])
  const [accfetched, setaccfetched] = useState(false)

  if (!accfetched) {
    demographicsgetter({ setAcc, productId: product.objectId })
    setaccfetched(true)
  }

  function setAcc({ z }: { z: any }) {
    setAccounts(z)
  }

  let respon = []
  function transferPayment() {
    return new Promise((res, rej) => {
      if (!fetched) {
        setFetched(true)
        if (product !== undefined) {
          moralisFetch({
            onSuccess: async () => {
              setWasSuccessful()

              successsaver({
                productid: product.objectId,
                currency: currency,
                email: email,
              })

              if (requestCount < 1) {
                fetch('https://ipwhois.app/json/')
                  .then((res) => res.json())
                  .then(
                    (result) => {
                      let country = result.country
                      let city = result.city
                      let totalSpent = price

                      let json = JSON.stringify(data, null, 2)

                      // console.log(
                      //   'saving city: ',
                      //   city,
                      //   ' and country: ',
                      //   country,
                      //   ' and email: ',
                      //   email
                      // )

                      let demographicData = JSON.parse(json)

                      // console.log('demographicData: ', demographicData)

                      let subscriptions = 0
                      let purchases = 0

                      if (product.recurrence !== 'One time') {
                        subscriptions = 1
                      } else {
                        purchases = 1
                      }

                      if (demographicData.length !== 0) {
                        totalSpent += demographicData[0].totalSpent

                        subscriptions += demographicData[0].subscriptions
                        purchases += demographicData[0].purchases

                        if (data[0] !== undefined) {
                          data[0].destroy().then(() => {
                            demographicsadder({
                              country,
                              city,
                              email,
                              totalSpent,
                              subscriptions,
                              purchases,
                              productId: product.objectId,
                            })
                          })
                        }
                      } else {
                        demographicsadder({
                          country,
                          city,
                          email,
                          totalSpent,
                          subscriptions,
                          purchases,
                          productId: product.objectId,
                        })
                      }
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                      // console.log('error: ', error)
                    }
                  )
                setRequestCount(requestCount + 1)
              }

              res(respon[0])
              setcalled(false)
            },
            onError: (error) => {
              // console.log('error', error)
              setcalled(false)
              respon[0] = error
              setcalled(false)
              rej(respon[0])
            },
          })
        }
      }
    })
  }

  return (
    <div>
      <button
        onClick={async () => {
          if (!isAuthenticated) {
            await authenticate()
          }
          if (!isWeb3Enabled) {
            enableWeb3()
          }
          // console.log('chainId: ', chainId)

          if (chainId !== '0x89') {
            // console.log('switching chain')

            await switchNetwork('0x89')
            // closeModal()
            // alert('Error! Please switch to the MATIC network.')
          } else {
            if (isAuthenticated) {
              setUserData({ email_address: email })
              setcalled(true)
              await transferPayment()
            }
          }
        }}
        className='px-14 py-1 bg-primary rounded-sm flex justify-center items-center font-semibold cursor-pointer'
      >
        {!called ? (
          <span>Transfer</span>
        ) : (
          <div className='flex justify-center items-center '>
            <span>Transferring </span>
            <Loader type='Puff' color='black' height={20} width={30} />
          </div>
        )}
      </button>
    </div>
  )
}

export default TransferPayment
