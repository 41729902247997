import { Routes, Route, useParams } from 'react-router-dom'
import { Product } from './backend/Product'
import { Subscription } from './backend/Subscription'
import { Invoice } from './backend/invoice'
import { SaaSPayment } from './backend/SaasPayment'
import { Donation } from './backend/donation'
import { useEffect } from 'react'

declare const window: any

function App() {
  useEffect(() => {
    // Reload page on chain change
    window.ethereum.on('chainChanged', (chainId: any) => {
      if (chainId !== '0x89') window.location.reload()
    })
  }, [])

  return (
    <Routes>
      <Route
        path='/'
        element={
          <meta httpEquiv='refresh' content='0; url=http://lunarpay.in' />
        }
      />
      <Route path='donation/:id' element={<Donationloader />} />
      <Route path='product/:id' element={<Productloader />} />
      <Route path='invoice/:id' element={<InvoiceLoader />} />
      <Route path='subscribe/:id/:email' element={<SaasLoader />} />
      <Route
        path='subscription-management/:id'
        element={<Subscriptionloader />}
      />
    </Routes>
  )
}

function Productloader() {
  let params = useParams()
  return <Product productId={params.id} />
}

export const Donationloader = () => {
  let params = useParams()
  return <Donation productId={params.id} />
}
//Page for subcription Management
function Subscriptionloader() {
  let params = useParams()
  return <Subscription productId={params.id} />
}

function InvoiceLoader() {
  let params = useParams()
  return <Invoice productId={params.id} />
}

function SaasLoader() {
  let params = useParams()
  return <SaaSPayment productId={params.id} email={params.email} />
}

export default App
