// Modal
import { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import TransferPayment from '../backend/Transfer'
import ProductClass from '../classes/ProductClass'
import 'react-dropdown/style.css'
import { demographicsgetter } from '../API/demographics'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#1E1E1F',
    border: 'none !important',
  },
}

const EmailModal = ({
  product,
  recurrence,
  processor,
  receiverAddress,
  price,
  name,
  modalIsOpen,
  setIsOpen,
  setSuccessful,
  defaultCurrency,
}: {
  recurrence: string
  price: number
  receiverAddress: string
  processor: string
  name: string
  product: ProductClass
  modalIsOpen: boolean
  setIsOpen: (arg: boolean) => void
  setSuccessful: (arg: boolean) => void
  defaultCurrency: string
}) => {
  const [email, setEmail] = useState('')

  function closeModal() {
    setIsOpen(false)
  }

  function setWasSuccessful() {
    setSuccessful(true)
  }

  useEffect(() => {
    ReactModal.setAppElement('body')
    if (ReactModal.defaultStyles.overlay !== undefined)
      ReactModal.defaultStyles.overlay.backgroundColor = 'transparent'
  }, [])

  return (
    <ReactModal
      id='product_modal'
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Create Product Modal'
    >
      <div className='w-full h-full flex flex-col justify-center items-center gap-7 pb-7 pt-3 px-4'>
        <h3 className='text-3xl font-medium text-white'>
          {price.toFixed(2)} {defaultCurrency}
        </h3>
        <div className='flex flex-col justify-center items-center gap-5'>
          <div className='flex justify-center items-center gap-2'>
            <p className='font-medium text-sm'>EMAIL</p>
            <input
              type='email'
              name='email'
              id='email'
              placeholder='Enter email..'
              className='rounded-sm outline-none pl-2 font-display h-7 flex justify-center items-center'
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
        </div>
        {/* <div className='flex justify-center items-center gap-2'>
          <p className='font-medium text-sm'>CURRENCY</p>
          <Dropdown
            menuClassName='single-select h-20'
            options={dropdownOptions}
            onChange={(e) => {
              setCurrency(e.value)
            }}
            value={defaultOption}
            placeholder='Select an option'
          />
        </div> */}
        <TransferPayment
          email={email}
          receiverAddress={receiverAddress}
          invdata={'unreq'}
          product={product}
          price={price}
          currency={defaultCurrency}
          processor={processor}
          closeModal={closeModal}
          setWasSuccessful={setWasSuccessful}
        />
      </div>
    </ReactModal>
  )
}

export default EmailModal
