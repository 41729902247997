import { useState } from 'react'
import { IoMdMoon } from 'react-icons/all'
import TransferPayment from './Transfer'
import { invoicegetter } from '../API/invoice'

export const Invoice = ({ productId }: { productId: string }) => {
  const [wasSuccessful, setWasSuccessful] = useState(false)

  const [invoiceFetched, setInvoiceFetched] = useState<boolean>(false)
  const [invoiceObj, setInvoiceObj] = useState<any>(null)

  if (!invoiceFetched && productId !== undefined) {
    invoicegetter({
      setAcc: setInvoiceObj,
      address: productId as any,
    })
    setInvoiceFetched(true)
  }
  function closeModal() {
    // console.log('Works')
  }

  let json = JSON.stringify(invoiceObj, null, 2)
  const product = JSON.parse(json)

  let price = 0
  let name = ''
  let email = ''
  let on_suc = 'invoice'
  if (product !== undefined) {
    email = product.email_address
    price = product.price
  }

  function setSuccessful() {
    setWasSuccessful(true)
  }

  return (
    <div
      style={{
        backgroundImage: 'url(/images/moon_bg.png)',
      }}
      className='w-screen h-screen bg-moon bg-center bg-no-repeat bg-cover flex justify-center items-center'
    >
      <div
        className={
          'w-5/12 h-3/4 rounded-2xl bg-background flex flex-col justify-between items-center shadow-default' +
          (wasSuccessful ? ' hidden' : '')
        }
      >
        <div className='flex justify-center items-center w-full mt-3'>
          <div className='flex justify-center items-center gap-1'>
            <IoMdMoon className='text-2xl text-primary' />
            <p className='text-2xl text-primary font-medium'>lunar</p>
          </div>
        </div>
        <div className='flex flex-col justiy-center items-center gap-3 mb-5 w-full'>
          <h1 className='text-2xl flex items-center font-medium justify-center w-full'>
            {email}
          </h1>
          <h1 className='text-2xl flex items-center font-medium justify-center w-full'>
            {name}
          </h1>
          <h1 className='text-5xl text-primary flex items-center font-semibold justify-center mb-5 w-full'>
            {price} MATIC
          </h1>
          {/* <h5 className='text-grey text-2xl font-display font-medium my-1'></h5> */}
          <div></div>

          <TransferPayment
            email={email}
            receiverAddress={''}
            invdata={invoiceObj}
            product={product}
            price={price}
            currency='MATIC'
            processor={on_suc}
            closeModal={closeModal}
            setWasSuccessful={setSuccessful}
          />

          <p className='text-xs mt-5 font-extralight'>
            secured by <span className='font-bold'>lunar</span>
          </p>
        </div>
        <div></div>
      </div>
      <div
        className={
          ' w-96 h-3/4 rounded-2xl bg-background flex flex-col justify-between items-center shadow-default px-4 transition-all' +
          (wasSuccessful ? '' : ' hidden')
        }
      >
        <div className='h-full w-full flex flex-col justify-center items-center gap-7'>
          <svg
            className='checkmark mt-4'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 52 52'
          >
            <circle
              className='checkmark__circle'
              cx='26'
              cy='26'
              r='25'
              fill='none'
            />
            <path
              className='checkmark__check'
              fill='none'
              d='M14.1 27.2l7.1 7.2 16.7-16.8'
            />
          </svg>
          <div className='flex flex-col justify-center items-center'>
            <h1 className='font-display font-semibold text-2xl mb-4'>
              Success!
            </h1>
            <p className='font-display font-medium text-center bottom-0'>
              {' '}
              Your transaction has successfully been logged to the blockchain
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
