export function successsaver({
  productid,
  currency,
  email,
}: {
  productid: number
  currency: string
  email: string
}) {
  let xhr = new XMLHttpRequest()
  let endpoint = process.env.REACT_APP_API_ENDPOINT
  let url = endpoint + 'api/v1/payment'
  xhr.open('POST', url)
  // xhr.setRequestHeader('Authorization', `Token ${token}`)
  xhr.setRequestHeader('Content-Type', 'application/json')
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4) {
      // let z: any = JSON.parse(xhr.responseText)
      // console.log('response:', xhr.responseText)
    }
  }
  // console.log('Sending')
  let json = JSON.stringify({
    product: productid,
    currency: currency,
    email: email,
  })
  xhr.send(json)
}
