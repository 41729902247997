import {
  useMoralis,
  // useMoralisCloudFunction,
} from 'react-moralis'

import ProductClass from '../classes/ProductClass'
import { useState } from 'react'
import no_name from '../lunar_logos/no-name.svg'
import '../css/extension.css'
import EmailModal from '../components/EmailModal'
import { productgetter, productuser } from '../API/products'

export const Product = ({ productId }: { productId: string }) => {
  const { enableWeb3, isWeb3Enabled, authenticate, isAuthenticated } =
    useMoralis()

  // console.log('productId:', productId)

  const [modalIsOpen, setIsOpen] = useState(false)
  const [wasSuccessful, setWasSuccessful] = useState(false)

  const [usr, setusr] = useState<any>(null)
  const [usrfet, setusrfet] = useState<any>(null)

  const [productObj, setProductObj] = useState<any>(null)
  const [prooductFetched, setProductFetched] = useState<boolean>(false)

  if (!usrfet && productId !== undefined) {
    productuser({
      address: productId as any,
      setAcc: setusr,
    })
    setusrfet(true)
  }

  if (!prooductFetched && productId !== undefined) {
    productgetter({
      setAcc: setProductObj,
      address: productId as any,
    })
    setProductFetched(true)
  }

  let json = JSON.stringify(productObj, null, 2)
  const product: ProductClass = JSON.parse(json)

  let recurrence = ''
  let price = 0
  let name = ''
  let on_suc = 'subscription'

  if (product !== undefined && product) {
    recurrence = product.recurrence
    price = product.price
    name = product.name
  }

  // console.log('product: ', product)

  const defaultCurrency = 'MATIC'

  return (
    <div
      style={{
        backgroundImage: 'url(/lunar_logos/no-name.svg)',
        backgroundPosition: '200px 0%',
        filter: modalIsOpen ? 'blur(10px)' : 'none',
        transition: 'all 0.5s',
      }}
      className='w-screen h-screen bg-moon bg-center bg-no-repeat bg-cover flex justify-center items-center'
    >
      <EmailModal
        name={name}
        price={price}
        receiverAddress={usr}
        recurrence={recurrence}
        product={product}
        processor={on_suc}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        setSuccessful={setWasSuccessful}
        defaultCurrency={defaultCurrency}
      />
      <div
        className={
          'w-5/12 h-3/4 rounded-2xl bg-background flex flex-col justify-between items-center shadow-default transition-all' +
          (wasSuccessful ? ' hidden' : '')
        }
      >
        <div className='flex justify-center items-center w-full mt-3'>
          <div className='flex justify-center items-center gap-1'>
            <img src={no_name} className='w-12 mt-2' alt='' />
          </div>
        </div>
        <div
          className={
            'flex flex-col justiy-center items-center gap-3 mb-5 w-full'
          }
        >
          <h1 className='text-2xl flex items-center font-medium justify-center w-full'>
            {name}
          </h1>
          <h1 className='text-5xl text-primary flex items-center font-semibold justify-center mb-5 w-full'>
            {price} {defaultCurrency}
          </h1>
          <h5 className='text-grey text-2xl font-display font-medium my-1'>
            {recurrence}
          </h5>
          <div
            onClick={async () => {
              if (!isAuthenticated) {
                await authenticate()
              }
              if (!isWeb3Enabled) {
                enableWeb3()
              }
              setIsOpen(true)
            }}
            className='w-60 bg-primary h-11 flex justify-center items-center rounded-sm font-semibold mt-5 font-display text-xl shadow-button cursor-pointer'
          >
            Pay
          </div>
          <p className='text-xs mt-5 font-extralight'>
            secured by <span className='font-bold'>LunarPay</span>
          </p>
        </div>
        <div></div>
      </div>

      <div
        className={
          ' w-96 h-3/4 rounded-2xl bg-background flex flex-col justify-between items-center shadow-default px-4 transition-all' +
          (wasSuccessful ? '' : ' hidden')
        }
      >
        <div className='h-full w-full flex flex-col justify-center items-center gap-7'>
          <svg
            className='checkmark mt-4'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 52 52'
          >
            <circle
              className='checkmark__circle'
              cx='26'
              cy='26'
              r='25'
              fill='none'
            />
            <path
              className='checkmark__check'
              fill='none'
              d='M14.1 27.2l7.1 7.2 16.7-16.8'
            />
          </svg>
          <div className='flex flex-col justify-center items-center'>
            <h1 className='font-display font-semibold text-2xl mb-4'>
              Success!
            </h1>
            <p className='font-display font-medium text-center bottom-0'>
              {' '}
              Your transaction has successfully been logged to the blockchain
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

//<TransferPayment
//email={email}
//product={product}
//recurrence={recurrence}
//price={price}
//name={name}
//closeModal={closeModal}
///>
