export async function demographicsgetter({
  setAcc,
  productId,
}: {
  setAcc: ({ z }: { z: any }) => void
  productId: string
}) {
  let xhr = new XMLHttpRequest()
  let endpoint = process.env.NEXT_PUBLIC_API_ENDPOINT
  let url = endpoint + 'api/v1/demographics/product'
  xhr.open('GET', url)
  xhr.setRequestHeader('Content-Type', 'application/json')
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      let z: any = JSON.parse(xhr.responseText)['items']
      setAcc({ z })
    }
  }

  let z = JSON.stringify({ product: productId })

  xhr.send(z)
}

export async function demographicsadder({
  country,
  city,
  email,
  totalSpent,
  subscriptions,
  purchases,
  productId,
}: {
  country: string
  city: string
  email: string
  totalSpent: number
  subscriptions: number
  purchases: number
  productId: string
}) {
  let xhr = new XMLHttpRequest()
  let endpoint = process.env.REACT_APP_API_ENDPOINT
  let url = endpoint + 'api/v1/demographics/add'
  xhr.open('POST', url)
  xhr.setRequestHeader('Content-Type', 'application/json')
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4) {
      // let z: any = JSON.parse(xhr.responseText)
      console.log('response:', xhr.responseText)
    }
  }
  let z = JSON.stringify({
    country: country,
    city: city,
    email: email,
    totalSpent: totalSpent,
    subscriptions: subscriptions,
    purchases: purchases,
    product: productId,
  })
  xhr.send(z)
}
