export async function invoicegetter({
  setAcc,
  address,
}: {
  setAcc: ({ z }: { z: any }) => void
  address: number
}) {
  if (address !== null) {
    let xhr = new XMLHttpRequest()
    let endpoint = process.env.REACT_APP_API_ENDPOINT
    let url = endpoint + 'api/v1/invoices'
    xhr.open('GET', url)
    //  xhr.setRequestHeader('Authorization', `Token ${token}`)
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        let z: any = JSON.parse(xhr.responseText)
        setAcc(z)
      }
    }

    let data = { Address: address }
    let t = JSON.stringify(data)
    xhr.send(t)
  }
}
