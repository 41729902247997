export async function productgetter({
  setAcc,
  address,
}: {
  setAcc: ({ z }: { z: any }) => void
  address: number
}) {
  if (address !== null) {
    let xhr = new XMLHttpRequest()
    let endpoint = process.env.REACT_APP_API_ENDPOINT
    let url = endpoint + 'api/v1/products/fetch'
    xhr.open('POST', url)
    //  xhr.setRequestHeader('Authorization', `Token ${token}`)
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        let z: any = JSON.parse(xhr.responseText)

        setAcc(z)
      }
    }

    let data = { Address: address }
    let t = JSON.stringify(data)
    xhr.send(t)
  }
}

export async function productadder({
  name,
  price,
  recurrence,
  token,
}: {
  name: string
  price: any
  recurrence: string
  token: string
}) {
  if (token !== null) {
    let xhr = new XMLHttpRequest()
    let endpoint = process.env.REACT_APP_API_ENDPOINT
    let url = endpoint + 'api/v1/products/add'
    xhr.open('POST', url)
    xhr.setRequestHeader('Authorization', `Token ${token}`)
    xhr.setRequestHeader('Content-Type', 'application/json')
    let z = JSON.stringify({
      name: name,
      price: price,
      recurrence: recurrence,
    })
    xhr.send(z)
  }
}

export async function productuser({
  setAcc,
  address,
}: {
  setAcc: ({ z }: { z: any }) => void
  address: number
}) {
  if (address !== null) {
    let xhr = new XMLHttpRequest()
    let endpoint = process.env.REACT_APP_API_ENDPOINT
    let url = endpoint + 'api/v1/products/user'
    xhr.open('POST', url)
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        let z: any = JSON.parse(xhr.responseText)['user']
        // console.log('User Response: ', xhr.responseText)

        setAcc(z)
      }
    }

    let data = { Address: address }
    let t = JSON.stringify(data)
    xhr.send(t)
  }
}

export async function currencygetter({
  setAcc,
  productid,
}: {
  setAcc: ({ z }: { z: any }) => void
  productid: string
}) {
  let xhr = new XMLHttpRequest()
  let endpoint = process.env.REACT_APP_API_ENDPOINT
  let url = endpoint + 'api/v1/accepted_currencies/product'
  xhr.open('POST', url)
  // xhr.setRequestHeader('Authorization', `Token ${token}`)
  xhr.setRequestHeader('Content-Type', 'application/json')
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4) {
      let z: any = JSON.parse(xhr.responseText)
      // console.log('response:', xhr.responseText)

      setAcc(z)
    }
  }
  let json = JSON.stringify({ product: productid })
  xhr.send(json)
}
