import {
  useMoralis,
  useMoralisQuery,
  // useMoralisCloudFunction,
} from "react-moralis";

import { useState } from "react";
import no_name from "../lunar_logos/no-name.svg";
import "../css/extension.css";
import DonationModal from "../components/DonationModal";

export const Donation = ({ productId }: { productId: string }) => {
  const { enableWeb3, isWeb3Enabled, authenticate, isAuthenticated } =
    useMoralis();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [wasSuccessful, setWasSuccessful] = useState(false);
  const [user, setUser] = useState("");
  let url = process.env.REACT_APP_FETCH + "/" + productId;
  let x = fetch(url)
    .then((res) => res.json())
    .then((result) => {
      setUser(result[0]["managed_account_pub"]);
    });

  return (
    <div
      style={{
        backgroundImage: "url(/lunar_logos/no-name.svg)",
        backgroundPosition: "200px 0%",
        filter: modalIsOpen ? "blur(10px)" : "none",
        transition: "all 0.5s",
      }}
      className="w-screen h-screen bg-moon bg-center bg-no-repeat bg-cover flex justify-center items-center"
    >
      <DonationModal
        modalIsOpen={modalIsOpen}
        user={user}
        setIsOpen={setIsOpen}
        setSuccessful={setWasSuccessful}
      />
      <div
        className={
          "w-5/12 h-3/4 rounded-2xl bg-background flex flex-col justify-between items-center shadow-default transition-all" +
          (wasSuccessful ? " hidden" : "")
        }
      >
        <div className="flex justify-center items-center w-full mt-3">
          <div className="flex justify-center items-center gap-1">
            <img src={no_name} className="w-12 mt-2" alt="" />
          </div>
        </div>
        <div
          className={
            "flex flex-col justiy-center items-center gap-3 mb-5 w-full"
          }
        >
          <h1 className="text-2xl w-60 flex items-center font-medium justify-center w-full">
            Donation
          </h1>
          <div
            onClick={async () => {
              setIsOpen(true);
              if (!isAuthenticated) {
                await authenticate();
              }
              if (!isWeb3Enabled) {
                await enableWeb3();
              }
            }}
            className="w-60 bg-primary h-11 flex justify-center items-center rounded-sm font-semibold mt-5 font-display text-xl shadow-button cursor-pointer"
          >
            Pay
          </div>
          <p className="text-xs mt-5 font-extralight">
            secured by <span className="font-bold">lunar</span>
          </p>
        </div>
        <div></div>
      </div>

      <div
        className={
          " w-96 h-3/4 rounded-2xl bg-background flex flex-col justify-between items-center shadow-default px-4 transition-all" +
          (wasSuccessful ? "" : " hidden")
        }
      >
        <div className="h-full w-full flex flex-col justify-center items-center gap-7">
          <svg
            className="checkmark mt-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
          <div className="flex flex-col justify-center items-center">
            <h1 className="font-display font-semibold text-2xl mb-4">
              Success!
            </h1>
            <p className="font-display font-medium text-center bottom-0">
              {" "}
              Your transaction has successfully been logged to the blockchain
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

//<TransferPayment
//email={email}
//product={product}
//recurrence={recurrence}
//price={price}
//name={name}
//closeModal={closeModal}
///>
